import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import sanitizeHtml from 'sanitize-html';

// UI Kit
import Button from 'ui-kit/button/button';
import LoadingMessage from 'ui-kit/loading-message/loading-message';
import SpinnerInline from 'ui-kit/spinner-inline/spinner';
import ToastBox from 'ui-kit/toast-box/toast-box';

// Components
import PaymentCard from 'components/payment-methods/payment-card/payment-card.component';

import { accountIsLoadingPaymentMethodsSelector } from 'state/account/account.selectors';
import { MembershipPlanStatusEnum } from 'state/membership/membership.helpers';
import {
    membershipDetailSelector,
    membershipIsLoadingPaymentMethodsSelector
} from 'state/membership/membership.selector';

// States and types
import { CreditCardPayload } from 'types/credit-card';
import { MembershipPlanStatusType } from 'types/membership';

import { getPhoneNumber } from 'util/globalVariables';
import { detectCardType } from 'util/payments';
import { convertToTitleCase } from 'util/string';

// Styles
import './manage-membership.styles.scss';

export interface ManageMembershipProps {
    paymentFrequency: string;
    creditCardInfo?: CreditCardPayload;
    planStatus?: MembershipPlanStatusType;
    onChangePaymentMethod: () => void;
    onChangePaymentFrequency?: () => void;
}

const ManageMembership = ({
    onChangePaymentMethod,
    paymentFrequency,
    creditCardInfo,
    planStatus
}: ManageMembershipProps): React.ReactElement => {
    const { t } = useTranslation();
    const membershipDetails = useSelector(membershipDetailSelector);
    const isLoading = useSelector(membershipIsLoadingPaymentMethodsSelector);
    const isPaymentMethodsLoading = useSelector(accountIsLoadingPaymentMethodsSelector);

    if (isLoading) {
        return (
            <div className="manage-membership">
                <LoadingMessage isVisible text={t(`pages.profile.membership.manageMembership.loading`)} />
            </div>
        );
    }

    return (
        <div className="manage-membership">
            <div className="manage-membership-method">
                <h4 className="manage-membership-title">
                    {t('pages.profile.membership.manageMembership.paymentMethod')}
                </h4>
                <p>{t('pages.profile.membership.manageMembership.paymentInfo')}</p>
            </div>

            {planStatus === MembershipPlanStatusEnum.PaymentDue && membershipDetails?.lastPaymentDate && (
                <div className="manage-membership-blurb">
                    <ToastBox variant="danger" icon="warning">
                        {t('pages.profile.membership.manageMembership.paymentDueAlert', {
                            lastPaymentDate: membershipDetails?.lastPaymentDate,
                            lastPaymentAmount: membershipDetails?.lastPaymentAmount
                        })}
                    </ToastBox>
                </div>
            )}

            {creditCardInfo && !isPaymentMethodsLoading ? (
                <div className="manage-membership-container">
                    <PaymentCard
                        card={creditCardInfo}
                        cardHolder={convertToTitleCase(
                            `${creditCardInfo?.cardFirstName} ${creditCardInfo?.cardLastName}`
                        )}
                        cardType={
                            creditCardInfo?.cardType
                                ? creditCardInfo.cardType
                                : `${detectCardType(String(creditCardInfo?.cardSeqNum))?.index}`
                        }
                        endingDigits={creditCardInfo?.cardNumber.slice(-4)}
                        expiryMonth={String(creditCardInfo.cardMonthNum)}
                        expiryYear={creditCardInfo?.cardYear}
                        key={`payment-card`}
                        isDefaultCard
                        showLabel
                        isBorderlessCard
                        isMembershipSettings
                    />
                    <Button
                        type="button"
                        label={t('pages.profile.membership.manageMembership.changeBtn')}
                        onClick={onChangePaymentMethod}
                        variant="text"
                        className="btn-text-blue btn-underline membership-button"
                    />
                </div>
            ) : (
                isPaymentMethodsLoading && <SpinnerInline />
            )}

            <div className="manage-membership-container">
                <div className="manage-membership-frequency">
                    <h4 className="manage-membership-title">
                        {t('pages.profile.membership.manageMembership.paymentFrequency')}
                    </h4>
                    <p>{paymentFrequency}</p>
                </div>
            </div>

            <div className="manage-membership-cancel">
                <h4 className="manage-membership-title">
                    {t('pages.profile.membership.manageMembership.cancelMembershipTitle')}
                </h4>
                <ToastBox variant="info" icon="info">
                    <div className="toastbox-membership-info">
                        <p
                            dangerouslySetInnerHTML={{
                                __html: sanitizeHtml(
                                    t('pages.profile.membership.manageMembership.cancelMembershipDescription', {
                                        phoneNumber: getPhoneNumber({ isEnd: true })
                                    })
                                )
                            }}
                        />
                    </div>
                </ToastBox>
            </div>
        </div>
    );
};

export default ManageMembership;
